export default (props = {}) => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<g id="handshake 1" clip-path="url(#clip0_145_1162)">
<g id="Group">
<path id="Vector" d="M11.1038 0.749898L10.9665 0.612632C10.3698 0.0159523 9.4259 0.109859 8.89844 0.748062L10.0016 1.85201L11.1038 0.749898Z" fill="#E5543C"/>
<path id="Vector_2" d="M19.8287 13.3381L18.5541 12.0638C17.6833 12.9348 15.8029 14.8156 14.3249 16.2928C13.6214 16.9964 12.8072 17.5579 11.9023 17.9646L13.5533 19.6152C13.7821 19.844 14.1531 19.8439 14.382 19.6151L19.8288 14.1668C20.0576 13.9379 20.0576 13.5669 19.8287 13.3381Z" fill="#E5543C"/>
<path id="Vector_3" d="M18.2078 10.7523C19.4382 9.42603 17.6046 7.58958 16.2787 8.82119L16.7601 8.33806C17.9905 7.01056 16.1572 5.1754 14.8277 6.40697L15.3125 5.92384C16.5439 4.59521 14.7071 2.76111 13.3801 3.9924L13.8649 3.50962C15.097 2.18029 13.2575 0.347319 11.9324 1.57822L10.8296 2.68099L11.7951 3.6472C13.21 5.02505 12.4745 7.52704 10.5447 7.92044C10.3618 8.84365 9.55077 9.66529 8.61585 9.85095C8.43292 10.7759 7.6205 11.5982 6.68323 11.783C6.28163 13.7121 3.80214 14.4497 2.41081 13.0292C2.41081 13.0293 1.44597 12.0638 1.44597 12.0638L0.171631 13.338C-0.0572363 13.5668 -0.0571973 13.9379 0.17167 14.1667L5.62159 19.6151C5.85046 19.8439 6.22147 19.8439 6.45026 19.615L8.55229 17.5121C10.4062 17.5121 12.1851 16.7753 13.4961 15.4639C15.4867 13.4744 18.2078 10.7523 18.2078 10.7523Z" fill="#E5543C"/>
<path id="Vector_4" d="M3.2394 12.2009C4.56761 13.4326 6.40284 11.597 5.17186 10.2695C6.50014 11.5011 8.3353 9.66561 7.10432 8.33807C8.43151 9.57018 10.2633 7.73178 9.03339 6.40697C10.3599 7.63811 12.1975 5.80436 10.9658 4.47553L8.07053 1.57822C7.53452 1.04526 6.67069 1.04526 6.13811 1.57822C5.60546 2.11119 5.60546 2.9767 6.13811 3.50967L6.61948 3.99244C5.29421 2.75994 3.45405 4.60147 4.69046 5.92389L5.17182 6.40697C3.84421 5.17483 2.00827 7.01068 3.2394 8.33807L3.6946 8.79303C2.35382 7.61666 0.575222 9.44416 1.79171 10.7523C1.79175 10.7522 3.2394 12.2009 3.2394 12.2009Z" fill="#E5543C"/>
</g>
</g>
<defs>
<clipPath id="clip0_145_1162">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
