export default (props = {}) => <svg
	width="16"
	height="16"
	viewBox="0 0 16 16"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	stroke="#EDEBD7"
{...props}>
	<path
		d="M2 5L6.30294 10.1575C7.2402 11.2808 8.7598 11.2808 9.69706 10.1575L14 5"
		stroke-width="1.5"
	/>
</svg>
